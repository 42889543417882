<template>
  <div>
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <div class="client-home-advantage">

        <b-row class="text-center m-2">
          <b-col xl="12">
            <b-button
              variant="link"
              @scroll="show"
            >
              <h1 style="color:white">
                服務優勢
              </h1>
            </b-button>
          </b-col>
          <b-col xl="12">
            <p>我們專注於提供安全、穩定的抗DDOS遊戲主機</p>
          </b-col>
        </b-row>

        <b-row
          v-for="(iconData,index) in IconData"
          :key="index"
        >
          <!-- <b-card-group> -->
          <b-col
            v-for="(data,icon) in IconData[index]"
            :key="icon"
            class="home-advantage-item"
            md="4"
            xs="12"
          >
            <transition name="bounce">
              <b-card
                v-if="show"
                class="text-center advantage-item-card"
              >
                <!-- class="text-body animated infinite wobble delay-2s" -->
                <b-card-title>
                  <font-awesome-icon
                    :icon="data.icon"
                    size="5x"
                    style="color:white"
                  />
                </b-card-title>
                <b-card-body class="text-center">
                  <b-card-text>
                    <h2
                      class="mb-25 font-weight-bolder"
                      style="color:white"
                    >
                      {{ data.title }}
                    </h2>
                  </b-card-text>
                  <b-card-text>{{ data.content }}</b-card-text>
                </b-card-body>
              </b-card>
            </transition>
          </b-col>
          <!-- </b-card-group> -->
        </b-row>

      </div>

    </b-container>
  </div>
</template>

<script>
import {
  BContainer, BRow, BCol, BCard, BCardText, BCardTitle, BCardBody, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    // BCardGroup,
    BCardText,
    BCard,
    BCardTitle,
    BCardBody,
    BButton,
  },
  data() {
    return {
      show: false,
      IconData: [
        [
          {
            icon: 'network-wired',
            title: 'BGP專線',
            content: '優先權最高的線路，主機設備在台灣提供給客戶最好的連線品質，並且搭海外IP，讓主機更安全更穩定。',
          },
          {
            icon: 'shield-alt',
            title: '高抗防火牆',
            content: '台灣流量清洗中心使用 金盾 的高階防火牆更有效的抗DDOS、高效防御DDOS還有Layer 4防護有效緩解UDP攻擊，不限制攻擊次數。',
          },
          {
            icon: 'server',
            title: '自有設備代管',
            content: '全伺服器規格，採用Intel E3或E5高級CPU，記憶體支援ECC或REG，非一般家用電腦，讓您的服務更穩定。',
          },
        ],
        [
          {
            icon: 'life-ring',
            title: '專業技術團隊',
            content: '超過10年經驗的IT工程師，專精於各類型攻擊，為您提供專業的防禦技術。',
          },
          {
            icon: 'hdd',
            title: 'SSD硬碟',
            content: '遊戲伺服器全配SSD硬碟，讓您的遊戲伺服器讀寫不卡頓。',
          },
          {
            icon: 'cloud-upload-alt',
            title: '每日安全性備份',
            content: '在我們所有的抗DDOS攻擊雲端VPS主機，皆有每日安全性備份',
          },
        ],
      ],
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandle) // 绑定页面滚动事件
  },
  methods: {
    scrollHandle(e) {
      const top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
      if (top >= 400) {
        this.show = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.client-home-advantage {
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding: 3rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  .home-advantage-item{
    // padding: 30px;
    .advantage-item-card{
      background-color:transparent;
      margin:0px auto;
      max-width: 400px;
      // background-color:rgb(33, 0, 54);
    }
  }
}
// 動畫
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

</style>
