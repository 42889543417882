<template>
  <swiper
    class="swiper-navigations"
    :options="swiperOptions"
    :style="`z-index:${homeStyleInit? 100: 1000} `"
  >
    <swiper-slide
      v-for="(data,index) in swiperData"
      :key="index"
    >
      <video-background
        class="video-background"
        :src="data.src"
        :overlay="isDark ? 'linear-gradient(#00000050,#00000088)' : 'linear-gradient(#00000030,#00000030)'"
      >
        <b-container class="carousel-text">
          <div>

            <h2 class="carousel-title">
              {{ data.title }}
            </h2>

            <h1 class="carousel-content">
              {{ data.content }}
            </h1>

            <h3 class="carousel-slogan">
              {{ data.sloganHead }}
              <span style="color:red;">{{ data.sloganStrong }}</span>
              {{ data.sloganEnd }}
              <!-- 優化寫法 -->
            </h3>

            <b-button
              v-if="data.button"
              class="carousel-button"
              variant="primary"
              @click="contect"
            >
              {{ data.button }}
            </b-button>
          </div>

          <!-- <div class="scollTip">
            <a>123</a>
          </div> -->
        </b-container>

      </video-background>
    </swiper-slide>

    <div
      slot="button-next"
      class="swiper-button-next"
    />
    <div
      slot="button-prev"
      class="swiper-button-prev"
    />
  </swiper>
</template>

<script>
// API
import useAppConfig from '@core/app-config/useAppConfig'
import { ref, computed, watch } from '@vue/composition-api'
import axios from '@axios'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'

// UI
import { BContainer, BButton } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VideoBackground from 'vue-responsive-video-background-player'

export default {
  name: 'VideoCarouselPage',
  components: {
    VideoBackground,
    Swiper,
    SwiperSlide,
    BButton,
    BContainer,
  },
  data() {
    return {
      swiperData: [
        {
          src: require('@/assets/video/04.mp4'),
          title: '抗攻擊遊戲主機',
          content: '提供最好的遊戲伺服器',
          sloganHead: '讓你的遊戲',
          sloganStrong: '24H',
          sloganEnd: '不打烊',
        },
        {
          src: require('@/assets/video/05.mp4'),
          title: '我們專注於遊戲線路防禦',
          content: 'BGP專線+硬體防火牆',
          sloganHead: '擁有超過六年',
          sloganStrong: '防禦DDOS',
          sloganEnd: '經驗',
        },
        {
          src: require('@/assets/video/06.mp4'),
          title: '保證線路穩定、低延遲',
          content: '最專業的技術團隊支援',
          sloganHead: '服務超過',
          sloganStrong: '1,583',
          sloganEnd: '位客戶',
        },
        {
          src: require('@/assets/video/07.mp4'),
          title: '還在用美工代購主機?出問題找誰?',
          content: '真正機房直營、自有設備',
          button: '聯絡我',
        },
      ],
      swiperOptions: {
        spaceBetween: 30,
        effect: 'fade',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  methods: {
    contect() {
      axios.get('/api/clearCache/fda04c6f5965f9bbc313d30f0de16ef3')
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    // Scroll Listener
    const { scrolledTo } = useScrollListener()

    const homeStyleInit = ref(false)

    watch(scrolledTo, val => {
      if (val !== 0) homeStyleInit.value = true
    })

    return {
      isDark,

      homeStyleInit,
    }
  },
}
</script>

<style lang="scss" scoped>
.swiper-navigations{
  .video-background{
    height: 100vh;
    .carousel-text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: 100vh;
      .carousel-title{
        color: white;
        font-size: 3vmin;
      }
      .carousel-content{
        color: rgb(0, 221, 255);
        font-weight: bold;
        font-size: 5vmin;
      }
      .carousel-slogan{
        color: rgb(255, 174, 0);
        font-size: 2.5vmin;
        margin-bottom:20px;
      }
    }
    // .scollTip {
    //   position: fixed;
    //   bottom: 20%;
    //   left:50%;
    //   margin-left: -10px;
    // }
  }
  .swiper-button-next{
    color: rgba(255, 255, 255, 0.1);
  }
  .swiper-button-prev{
    color: rgba(255, 255, 255, 0.1);
  }
}

</style>
