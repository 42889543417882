<template>
  <div class="home-footer">
    <b-container>
      <b-row>

        <b-col
          cols="12"
          lg="3"
          sm="6"
        >
          <h4 class="footer-title">
            產品服務
          </h4>
          <b-list-group class="footer-list">
            <b-list-group-item>虛擬主機 / Web Hosting</b-list-group-item>
            <b-list-group-item>雲端主機 / Cloud Virtual Private Server</b-list-group-item>
            <b-list-group-item>實體主機 / Dedicated Servers</b-list-group-item>
            <b-list-group-item>機房託管 / IDS Co-Location</b-list-group-item>
            <b-list-group-item>加值服務 / Value Added Service</b-list-group-item>
            <b-list-group-item>網路專線 / Ethernet Circuit</b-list-group-item>
          </b-list-group>
        </b-col>

        <b-col
          cols="12"
          lg="3"
          sm="6"
        >
          <h4 class="footer-title">
            使用教學
          </h4>
          <b-list-group class="footer-list">
            <b-list-group-item>遠端伺服器教學</b-list-group-item>
            <b-list-group-item>如何重裝VPS</b-list-group-item>
            <b-list-group-item>如何安裝實體主機</b-list-group-item>
            <b-list-group-item>會員註冊及實名驗證</b-list-group-item>
            <b-list-group-item>建置 LNMP WORDPRESS</b-list-group-item>
          </b-list-group>
        </b-col>

        <b-col
          cols="12"
          lg="3"
          sm="6"
        >
          <h4 class="footer-title">
            訂購常見問題
          </h4>
          <b-list-group class="footer-list">
            <b-list-group-item>主機多久可以開通</b-list-group-item>
            <b-list-group-item>如何安裝作業系統</b-list-group-item>
            <b-list-group-item>什麼時候會收到發票</b-list-group-item>
            <b-list-group-item>我才繳完費怎麼又有帳單</b-list-group-item>
            <b-list-group-item>主機服務申請書</b-list-group-item>
          </b-list-group>
        </b-col>

        <b-col
          cols="12"
          lg="3"
          sm="6"
        >
          <h4 class="footer-title">
            即時服務
          </h4>
          <b-list-group class="footer-list">
            <b-list-group-item>聯絡我們</b-list-group-item>
            <b-list-group-item>線上客服</b-list-group-item>
          </b-list-group>
        </b-col>

      </b-row>

      <b-row class="text-center footer-botton">
        <b-col cols="12">
          <span>
            統一編號:24982684
          </span>
        </b-col>
        <b-col cols="12">
          <span>
            COPYRIGHT  © 2012-{{ new Date().getFullYear() }} 曠世國際有限公司
            <span class="d-none d-sm-inline-block"> , All rights Reserved</span>
          </span>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
import {
  BContainer, BRow, BCol, BListGroup, BListGroupItem, // BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    // BLink,
    BListGroup,
    BListGroupItem,
  },
}
</script>

<style lang="scss" scoped>
  .home-footer{
    background-color: #161d31;//#161d31
    padding:50px 0;
    // text-align:center;
    // font-size: 1.2rem;
    .footer-title{
      color:white;
      font-weight: bold;
      font-size: 1.5rem;
    }
    .footer-list{
      .list-group-item {
        background-color: transparent;
        padding: 0;
        border: none;
        &:hover {
          background-color: transparent;
        }
      }
    }
    .footer-botton{
      padding-top: 30px;
    }
  }
</style>
