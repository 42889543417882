<template>
  <div class="client-home">
    <div>
      <home-carousel />
      <home-advantage />
      <home-footer />
    </div>
  </div>
</template>

<script>
// import { BContainer } from 'bootstrap-vue'

import HomeCarousel from './HomeCarousel.vue'
import HomeAdvantage from './HomeAdvantage.vue'
import HomeFooter from './HomeFooter.vue'

export default {
  components: {
    HomeCarousel,
    HomeAdvantage,
    HomeFooter,
  },
  data() {
    return {

    }
  },

}
</script>

<style lang="scss" scoped>
.client-home {
  background-color: royalblue;
  background-image: url('./assets/bg3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
}
</style>
